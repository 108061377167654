export default [
    {
        header: 'Home',
        action: 'read',
        resource: 'user',
    },
    {
        title: 'Statistics',
        route: 'Statistics',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'user',
    },
    {
        header: 'Complaints',
        action: 'read',
        resource: 'user',
    },
    {
        title: 'Complaints List',
        route: 'Complaints-list',
        action: 'read',
        resource: 'user',
        icon: 'ListIcon',
    },
    {
        header: 'Satisfaction',
        action: 'read',
        resource: 'user',
    },
    {
        title: 'Satisfaction List',
        route: 'satisfaction-list',
        action: 'read',
        resource: 'user',
        icon: 'SmileIcon',
    },
    {
        header: 'Employees',
        action: 'read',
        resource: 'user',
    },
    {
        title: 'Add Employee',
        route: 'employee-add',
        action: 'read',
        resource: 'user',
        icon: 'UserPlusIcon',
    },
    {
        title: 'Employees List',
        route: 'employee-list',
        action: 'read',
        resource: 'user',
        icon: 'ListIcon',
    }

]
