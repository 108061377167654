export default [

    {
        header: 'Categories Management',
        action: 'read',
        resource: 'admin',
    },
    {
        title: 'Category List',
        route: 'category-list',
        action: 'read',
        resource: 'admin',
        icon: 'InboxIcon',
    },
    {
        header: 'Municipalities',
        action: 'read',
        resource: 'admin',
    },
    {
        title: 'List',
        route: 'municipalities-list',
        action: 'read',
        resource: 'admin',
        icon: 'ListIcon',
    },
    {
        title: 'Add',
        route: 'municipalities-add',
        action: 'read',
        resource: 'admin',
        icon: 'UserPlusIcon',
    },
    {
        header: 'Ads',
        action: 'read',
        resource: 'admin',
    },
    {
        title: 'Ads List',
        route: 'ads-list',
        action: 'read',
        resource: 'admin',
        icon: 'ListIcon',
    },
    {
        title: 'Add',
        route: 'ads-add',
        action: 'read',
        resource: 'admin',
        icon: 'YoutubeIcon',
    },
]
